import React, { useEffect, useRef, useCallback } from 'react';
import useState from 'react-usestateref';
import axios from 'axios';
import Slider from '@mui/material/Slider';
import './LoadCT.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './infobar.css';
import InfoPelvisRT from './InfoPelvisRT';

  
const PelvisSagittal = ({pelvisCase}) => {

const [data, setData, dataRef] = useState({});
const [loading, setLoading] = useState(true);
const imgRef = useRef();
const wheeling = useRef(false);
const wheelingTimeout = useRef(false);
const imgContainer = useRef(null);
const currentIndex = useRef(0);
const [contrast, setContrast, contrastRef] = useState(0);
const [sliderVal, setSliderVal] = useState(0);
const [imgLen, setimgLen, imgLenRef] = useState(0);
const [infoBarRender, setinfoBarRender] = useState(true);

// Fetch data and set it to data object.
const fetchImages = async () => {
    setLoading(true) // Setting loading to true before getting new images to force rerendering
    var response = null
    try{
        // Get the images and save to response for use later on.
        response = await axios.get(process.env.REACT_APP_PELVIS_SAGITTAL_URL+'?case='+pelvisCase);
        setData(response.data.data);
        setimgLen(response.data.data[0].length-1)
    }catch (error) {
        console.error(error)
    }
    if (imgLenRef.current < 1){
        console.error("No images found!")
    }
    if (pelvisCase.includes("hnb") || pelvisCase.includes("h-n-b") ||
        pelvisCase.includes("head neck brain") || pelvisCase.includes("Head Neck Brain") || 
        pelvisCase.includes("Head-Neck-Brain") || pelvisCase.includes("head-neck-brain") ||
        pelvisCase.includes("Head-Neck") || pelvisCase.includes("Head Neck") ||
        pelvisCase.includes("head-neck") || pelvisCase.includes("head neck")) {

        setinfoBarRender(false)
    }
    else{
        setinfoBarRender(true)
    }

    setLoading(false); //Sets loading to false and allows render of image
    return
};

// Handles scrolling(wheel)
const onScroll = useCallback((e) => {
    const isNext = e.wheelDelta > 0;

    if (shouldPreventDefault({ isNext })) {
        e.preventDefault();
    }
    clearTimeout(wheelingTimeout.current);
    wheelingTimeout.current = setTimeout(function () {
        wheeling.current = false;
    }, 10);
    
    if (wheeling.current === true) {
        return null;
    }
    if (isNext) {
        // Scrolling for next image
        currentIndex.current = getNextCurrentIndex(currentIndex.current);
        imgRef.current.src = `data:image/png;base64,`+dataRef.current[contrastRef.current][currentIndex.current]

    } else {
        // Scrolling for previous image
        currentIndex.current = getPrevCurrentIndex(currentIndex.current);
        imgRef.current.src = `data:image/png;base64,`+dataRef.current[contrastRef.current][currentIndex.current]
        // Moves the slider every 10 image to ignore animation delays
        if (currentIndex.current % 10 == 0) {
            setSliderVal(currentIndex.current )
        } 
    }
    wheeling.current = true;

}, [imgRef]);

// Instantly stop scrolling instead of keep scrolling buffered events
const stopWheeling = useCallback(() => {
    wheeling.current = false;
}, []);


// ---- Controll scrolling index bounds ---- //
const shouldPreventDefault = useCallback(({ isNext }) => {
    if (!isNext && currentIndex.current > 0) {
      return true;
    }
    if (isNext && currentIndex.current <= imgLenRef.current) {
      return true;
    }
    return false;
}, []);
const getNextCurrentIndex = (index) => {
    if (index === imgLenRef.current) {
        setSliderVal(index)
        return index;
    }
    if (index % 10 == 0) {
        setSliderVal(index)
    } 
    return index + 1;
};
const getPrevCurrentIndex = (index) => {
    if (index === 0) {
        return index;
    }
    return index - 1;
};
// ---------------------------------------- //

// Handle changing slider event to change image
const handleChangeSlider = useCallback((e, newValue) => {
    currentIndex.current=newValue
    setSliderVal(newValue)
    imgRef.current.src = `data:image/png;base64,`+dataRef.current[contrastRef.current][currentIndex.current]
})


useEffect( () => {
    fetchImages()
    const imgContainerCurrent = imgContainer.current; //Limit wheel scroll to img container
    imgContainerCurrent.addEventListener("wheel", onScroll, true);
    return () => {
      imgContainerCurrent.removeEventListener("wheel", onScroll, false);
    }; 
}, [onScroll, stopWheeling, pelvisCase] );

// Change image if a new contrast is chosen
const handleContrast = (event) => {
    setContrast(event);
    imgRef.current.src = `data:image/png;base64,`+dataRef.current[event][currentIndex.current]
};


return (
    <div ref={imgContainer}>
    {loading && <div>Loading..<br/><img className='loadingimg' src='./bounceload.gif'/> </div>}
    {!loading && (
        <div>
            {infoBarRender &&( 
                <InfoPelvisRT/>
            )}
            <br/>
            <div>
                <img className='imageDiv'
                    ref={imgRef}
                    src={`data:image/png;base64,`+data[contrast][currentIndex.current]}
                    key={data}
                />
            </div>
            <br/>
            <div className="sliderimg">
                <Slider 
                    max={imgLenRef.current}
                    aria-label="Default" valueLabelDisplay="off"
                    onChange={handleChangeSlider} value={sliderVal} 
                    />
            </div>
            <br/>
            <div className='contrastDropDown'>
                <DropdownButton
                    title="CT Contrast"
                    onSelect={handleContrast}>
                        <Dropdown.Item eventKey="0">Soft Tissue (W:400 L:50)</Dropdown.Item>
                        <Dropdown.Item eventKey="1">Bone (W:1800 L:400)</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
        
    )}
    </div>
  )
}

export default PelvisSagittal;