import specLogo from './spectronic.png'
import './App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import useState from 'react-usestateref';
import React, { useEffect, useRef, useCallback } from 'react';
import PelvisCoronal from './PelvisCoronal';
import PelvisTransverse from './PelvisTransverse';
import InfoPelvisRT from './InfoPelvisRT';
import PelvisSagittal from './PelvisSagittal';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import NavDropdown from 'react-bootstrap/NavDropdown';


function App() {

  const [comp, setComp] = useState(0);
  const [pelvisCase, setPelvisCase, refPelvisCase] = useState();
  const [pelvisCaseList, setPelvisCaseList, refPelvisCaseList] = useState([null]);
  const [isBusy, setBusy] = useState(true)

  
  const changeComp = event => {
    setComp(event)
  };

  const fetchCases = async () => {
      try{
        const response = await axios.get(process.env.REACT_APP_PELVIS_CASES_URL);
        setPelvisCaseList(response.data.cases)
        setPelvisCase(response.data.cases[0])
      }catch (error) {
          console.error(error)
      }
        
      setBusy(false);
  };


  const caseSelect = sel => {
    setPelvisCase(sel)
  }

  useEffect(() => {
    fetchCases()
  }, [])  

  return (
    <div className="App">
      <header className="App-header">
        <img src={specLogo} className="App-logo" alt="logo" />
      </header>
      {/* <Button onClick={fetchCases}>TEst</Button> */}
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">MRI Planner</Navbar.Brand>
          <Nav className="me-auto">
            <NavDropdown title="Case"  id="nav-dropdown" menuVariant='dark'>
              {refPelvisCaseList.current.map((e) => {
                return <NavDropdown.Item key={e} eventKey={e} onClick={()=>caseSelect(e)}>{e}</NavDropdown.Item>;
              })}
            </NavDropdown>
            <Nav.Link id='0' onClick={() => { changeComp('0')}} >Transverse</Nav.Link>
            <Nav.Link id='1' onClick={() => { changeComp('1')}} >Coronal</Nav.Link>
            <Nav.Link id='2' onClick={() => { changeComp('2')}} >Sagittal</Nav.Link>            
          </Nav>
        </Container>
      </Navbar>
    
    
    {(comp == 0 && !isBusy)&&
    <>
      <PelvisTransverse pelvisCase={refPelvisCase.current}/>
    </>
    }
    {(comp == 1 && !isBusy)&&
    <> 
      <PelvisCoronal pelvisCase={refPelvisCase.current}/>
    </>
    }
    {(comp == 2 && !isBusy)&&
    <> 
      <PelvisSagittal pelvisCase={refPelvisCase.current}/>
    </>
    }
    </div>
  );
}

export default App;
