import './infobar.css';

function InfoPelvisRT() {
  return (
    <div>
        <div className='infobar'>
            <div style={{position: 'relative', left: '25%'}}>
                <div className="hipleftcontainer">
                    <div className="circlehipleft"></div>
                    <p>Left Femoral Head</p>
                </div>
                <div className="bladdercontainer">
                    <div className="circlebladder"></div>
                    <p>Bladder</p>
                </div>
                <div className="coloncontainer">
                    <div className="circlecolon"></div>
                    <p>Rektum</p>
                </div>
                <div className="hiprightcontainer">
                    <div className="circlehipright"></div>
                    <p>Right Femoral Head</p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default InfoPelvisRT;